@tailwind base;
@tailwind components;
@tailwind utilities;

button.mapboxgl-popup-close-button {
  line-height: 1 !important;
  padding: 2px 5px;
  margin: 3px;
  border-radius: 3px !important;
  font-size: 18px;
}

button.mapboxgl-popup-close-button:focus-visible {
  outline: none;
}
